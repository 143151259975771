<template>
  <div
    ref="wrapper"
    class="r-datepicker"
    :class="{ disabled, range: !isSingle }"
    @click="toggle"
  >
    <r-text v-if="label"> {{ label }}</r-text>

    <div
      :id="`datepicker-${id}`"
      class="r-datepicker__input"
      :class="{ error }"
    >
      <r-icon name="date-calendar" />

      <r-text :color-type="colorType">
        {{ inputValue }}
      </r-text>

      <r-button
        v-if="clearable"
        class="r-datepicker__clear"
        simple
        icon="clear-input"
        mini
        @click.prevent.stop="clear"
      />
    </div>

    <portal
      v-if="isOpenedCalendar"
      to="main-portal"
    >
      <div
        class="r-datepicker__table"
        :style="styles"
      >
        <table>
          <thead>
            <tr>
              <th
                :colspan="7"
                class="r-datepicker__header"
              >
                <r-button
                  :icon="{ name: 'chevron-down', rotate: 90 }"
                  simple
                  @click="decrementMonth"
                />
                <r-range-picker
                  :value="{ year, month }"
                  type="year-month"
                  @change="changeYearMonth"
                />
                <r-button
                  :icon="{ name: 'chevron-down', rotate: -90 }"
                  simple
                  @click="incrementMonth"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                v-for="(weekDay, i) in daysOfWeek"
                :key="i"
              >
                {{ weekDay }}
              </th>
            </tr>
            <tr>
              <td
                colspan="7"
                class="r-datepicker__divider"
              >
                <r-divider />
              </td>
            </tr>
            <tr
              v-for="(dateRow, index) in calendar"
              :key="index"
            >
              <td
                v-for="(date, i) in dateRow"
                :key="i"
                :class="dayClass(date)"
                @click="dateClick(date)"
                @mouseover="hoveredDate = date"
              >
                {{ date.getDate() }}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="type === 'datetime'"
          class="r-datepicker__footer"
        >
          <div
            class="r-datepicker__time"
            :class="{ range: !isSingle }"
          >
            <r-input-time
              :value="propTime?.[0]"
              :disabled="noDates"
              clearable
              @input="inputTime($event, 'startDate')"
            />
            <r-input-time
              v-if="!isSingle"
              :value="propTime?.[1]"
              :disabled="!rangeDate.endDate"
              clearable
              @input="inputTime($event, 'endDate')"
            />
          </div>

          <r-button
            type="primary"
            :disabled="noDates"
            @click="inputDate"
          >
            {{ $t('apply') }}
          </r-button>
        </div>
      </div>
    </portal>
  </div>
</template>

<script setup>
import i18n from '@/extensions/i18n'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import rDate from 'ritm-date'
import { generateId } from 'HELPERS'

// eslint-disable-next-line no-undef
const $emit = defineEmits(['input', 'start-selection'])

// eslint-disable-next-line no-undef
const props = defineProps({
  value: {
    type: [Date, Array, String, Number],
    default: null
  },
  label: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    validator: type => ['date', 'datetime'].includes(type),
    default: 'date'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: ''
  },
  clearable: {
    type: Boolean,
    default: false
  },
  pickerType: {
    type: String,
    validator: type => ['single', 'range'].includes(type),
    default: 'single'
  },
  minDate: {
    type: Function,
    default: null
  },
  maxDate: {
    type: Function,
    default: null
  },
  align: {
    type: String,
    validator: align => ['left', 'right'].includes(align),
    default: 'left'
  }
})

const format = {
  date: 'DD.MM.YYYY',
  datetime: 'DD.MM.YY • HH:mm'
}

const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map(e =>
  i18n.t(`${e}`)
)

const today = new Date()
today.setHours(0, 0, 0, 0)

const id = generateId()

const wrapper = ref(null)
const isOpenedCalendar = ref(false)
const rangeDate = ref({ startDate: null, endDate: null })
const hoveredDate = ref(null)
const month = ref(today.getMonth() + 1)
const year = ref(today.getFullYear())
const styles = ref(null)

const isSingle = computed(() => props.pickerType === 'single')

const noDates = computed(() => {
  const { startDate, endDate } = rangeDate.value

  return !startDate || (!isSingle.value && !endDate)
})

const propValue = computed(() => {
  if (isSingle.value) {
    return { startDate: props.value, endDate: props.value }
  }

  return { startDate: props.value?.[0], endDate: props.value?.[1] }
})

const propTime = computed(() => {
  if (!rangeDate.value.startDate) return null

  const start = rDate(rangeDate.value.startDate).format('HH:mm')

  if (isSingle.value) {
    return [start, start]
  }

  if (!rangeDate.value.endDate) return null

  const end = rDate(rangeDate.value.endDate).format('HH:mm')

  return [start, end]
})

const colorType = computed(() =>
  propValue.value.startDate ? null : 'secondary'
)

const inputValue = computed(() => {
  if (!propValue.value.startDate) return props.placeholder

  const startDate = getFormattedDate(propValue.value.startDate)

  if (isSingle.value) return startDate

  const endDate = getFormattedDate(propValue.value.endDate)

  return `${startDate} — ${endDate}`
})

const getFormattedDate = date => {
  return date ? rDate(date).format(format[props.type]) : null
}

const minDateFormatted = computed(() => {
  return props.minDate ? new Date(props.minDate()) : null
})
const maxDateFormatted = computed(() => {
  return props.maxDate ? new Date(props.maxDate()) : null
})

const calendar = computed(() => {
  const firstDay = new Date(year.value, month.value - 1, 1)
  const lastMonth = firstDay.getMonth()
  const lastYear = firstDay.getFullYear()
  const dayOfWeek = firstDay.getDay()
  const daysInLastMonth = new Date(lastYear, month.value - 1, 0).getDate()
  const dates = []
  let startDay

  if (dayOfWeek === 1) {
    startDay = daysInLastMonth - 6
  } else {
    startDay = daysInLastMonth - dayOfWeek + 1 + 1

    if (startDay > daysInLastMonth) startDay -= 7
  }

  const curDate = new Date(lastYear, lastMonth - 1, startDay, 0, 0, 0)

  for (let i = 0; i < 6; i++) {
    dates[i] = []
  }

  for (
    let i = 0, col = 0, row = 0;
    i < 6 * 7;
    i++, col++, curDate.setDate(curDate.getDate() + 1)
  ) {
    if (i > 0 && col % 7 === 0) {
      col = 0
      row++
    }

    dates[row][col] = new Date(curDate.getTime())
  }

  return dates
})

const setValues = () => {
  rangeDate.value = { ...propValue.value }
  const dt = rangeDate.value.endDate ? new Date(rangeDate.value.endDate) : today

  month.value = dt.getMonth() + 1
  year.value = dt.getFullYear()
}

const toggle = () => {
  isOpenedCalendar.value = !isOpenedCalendar.value

  if (isOpenedCalendar.value) {
    setValues()
    styles.value = calcPos()
  }
}

const changeYearMonth = value => {
  year.value = value?.year
  month.value = value?.month
}

const decrementMonth = () => {
  if (month.value === 1) {
    month.value = 12
    year.value--
  } else {
    month.value--
  }
}

const incrementMonth = () => {
  if (month.value === 12) {
    month.value = 1
    year.value++
  } else {
    month.value++
  }
}

const inputDate = () => {
  const { startDate, endDate } = rangeDate.value
  const date = isSingle.value ? startDate : [startDate, endDate]
  $emit('input', date)
  isOpenedCalendar.value = false
}

const inputTime = (value, key) => {
  const arr = value?.split(':')
  const dateValue = rangeDate.value[key]

  dateValue.setHours(arr?.[0] ?? 0)
  dateValue.setMinutes(arr?.[1] ?? 0)

  rangeDate.value = { ...rangeDate.value, [key]: dateValue }
}

const calcPos = () => {
  const style = {}
  const { left, right, top, bottom } = wrapper.value.getBoundingClientRect()
  const windowHeight = document.documentElement.clientHeight
  const windowWidth = document.documentElement.clientWidth
  const menuOnTop = bottom > windowHeight - 380
  const offset = props.align === 'right' ? windowWidth - right : left

  style[props.align] = Math.floor(offset) + 'px'

  if (menuOnTop) {
    style.bottom = windowHeight - top + 8 + 'px'
  } else {
    style.top = bottom + 8 + 'px'
  }

  return style
}

const dateClick = dt => {
  const date = new Date(dt)

  if (isSingle.value) {
    rangeDate.value = { startDate: date, endDate: date }

    if (props.type === 'date') {
      inputDate()
    }

    return
  }

  if (rangeDate.value.startDate && !rangeDate.value.endDate) {
    // if was selection
    if (rangeDate.value.startDate > date) {
      // reverse dates
      rangeDate.value.endDate = new Date(rangeDate.value.startDate)
      rangeDate.value.startDate = date
    } else {
      rangeDate.value.endDate = date
    }

    if (props.type === 'date') {
      inputDate()
    }
  } else {
    // start selection
    rangeDate.value.startDate = date
    rangeDate.value.endDate = null
    $emit('start-selection', date)
  }
}

const getRange = () => {
  const { startDate, endDate } = rangeDate.value

  if (isSingle.value) {
    return [startDate, startDate]
  }

  if (startDate && !endDate && hoveredDate.value) {
    if (hoveredDate.value > startDate) {
      return [startDate, hoveredDate.value]
    }

    return [hoveredDate.value, startDate]
  }

  return [startDate, endDate]
}

const dayClass = date => {
  const range = getRange()

  const dt = new Date(date)
  dt.setHours(0, 0, 0, 0)
  const time = dt.getTime()
  const start = new Date(range[0])
  start.setHours(0, 0, 0, 0)
  const end = new Date(range[1])
  end.setHours(0, 0, 0, 0)

  const startDate = time === start.getTime()
  const endDate = time === end.getTime()

  const dt_min_compare = new Date(dt).setHours(23, 59, 59, 999)
  const disabled =
    (minDateFormatted.value &&
      dt_min_compare < minDateFormatted.value.getTime()) ||
    (maxDateFormatted.value && time > maxDateFormatted.value.getTime())

  return {
    off: date.getMonth() + 1 !== month.value,
    weekend: date.getDay() === 6 || date.getDay() === 0,
    today: time == today.getTime(),
    active: startDate || endDate,
    'in-range': dt >= start && dt <= end,
    'start-date': startDate,
    'end-date': endDate,
    disabled
  }
}

const clear = () => {
  rangeDate.value = { startDate: null, endDate: null }
  $emit('input', null)
}

const closeMenu = e => {
  if (
    ((e.target.closest('.r-datepicker') ||
      e.target.closest('.r-datepicker__input')) &&
      e.target.closest(`#datepicker-${id}`)) ||
    e.target.closest('.r-datepicker__table') ||
    e.target.closest('.r-range-picker') ||
    e.target.closest('.r-range-picker__content')
  ) {
    return
  }

  isOpenedCalendar.value = false
}

onMounted(() => {
  setValues()
  document.addEventListener('scroll', closeMenu, true)
  document.addEventListener('click', closeMenu, true)
})

onUnmounted(() => {
  document.removeEventListener('scroll', closeMenu, true)
  document.removeEventListener('click', closeMenu, true)
})

watch(() => props.value, setValues)
</script>

<style lang="scss" scoped>
.r-datepicker {
  position: relative;
  display: grid;
  gap: 0.25rem;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.range {
    min-width: 230px;
  }

  &__clear {
    opacity: 0;
  }

  &__header {
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
    grid-auto-flow: column;
  }

  &__input {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 0.25rem;
    height: 36px;
    border-radius: $border-radius;
    padding: 0 0.5rem;
    transition: 0.16s;
    width: 100%;
    background: $field-bg;
    border: 1px solid $field-border;
    cursor: pointer;

    &:hover {
      .r-datepicker__clear {
        opacity: 1;
      }
    }

    &.error {
      border-color: $accent-danger;
    }
  }

  &__table {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background: $modal-bg;
    box-shadow: $box-shadow-s;
    border-radius: 4px;
    border: 1px solid $modal-overlay;
    z-index: 3000;
    padding: 0.5rem;

    table {
      table-layout: fixed;
      width: 260px;
    }

    td,
    th {
      line-height: 1;
      height: 32px;

      &.r-datepicker__divider {
        height: 8px;
        padding-bottom: 0.5rem;
      }
    }

    th {
      font-size: 10px;
      color: $text-secondary;
    }

    td {
      color: $text-primary;
      font-size: 12px;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;

      &.today {
        font-weight: 900;
      }

      &.disabled {
        text-decoration: none;
        pointer-events: none;
      }

      &.off {
        color: $text-secondary;
      }

      &:hover {
        color: $accent-primary;
      }

      &.in-range {
        background: $accent-primary-1;

        &.active {
          color: $white;
          background: $accent-primary;
        }
      }
    }
  }

  &__time {
    display: grid;
    gap: 0.5rem;

    &.range {
      grid-template-columns: 1fr 1fr;
    }

    &:not(.range) {
      justify-content: center;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>
