var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"r-dropdown",class:{ opened: _vm.isMenuShown },attrs:{"id":`rd-${_vm.id}`,"tabindex":"-1"}},[_c('div',{ref:"dropdown",staticClass:"r-dropdown__dropdown",on:{"click":_vm.toggleShown}},[_vm._t("default")],2),(_vm.isMenuShown)?_c('portal',{attrs:{"to":"main-portal"}},[_c('transition',{attrs:{"name":"unroll"}},[_c('div',{ref:"menu",staticClass:"r-dropdown__menu",style:({
          width: _vm.width ? `${_vm.width}px` : 'auto',
          maxWidth: `${_vm.menuMaxWidth}px`,
          maxHeight: `${_vm.menuMaxHeight}px`,
          [_vm.stickTo]: `${_vm.left || _vm.right}`,
          top: `${_vm.top}px`,
          bottom: `${_vm.bottom}px`,
          minWidth: `${_vm.minWidth}px`,
          overflow: _vm.overflow
        }),attrs:{"tabindex":"0"}},[(_vm.menuTitle)?_c('r-title',{staticClass:"r-dropdown__title",attrs:{"type":"subtitle-2"}},[_vm._v(" "+_vm._s(_vm.menuTitle)+" ")]):_vm._e(),(_vm.isMenuShown)?_vm._t("dropdown-menu"):_vm._e()],2)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }