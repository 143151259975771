import $store from 'STORE'

const getLocale = () => $store.getters.getLocale

export const getOptionTitle = (option, alias_key) => {
  if (!option) return

  const title = alias_key
    ? option[alias_key]
    : option.alias || option.title || option.name
  const isLocaleObject = title instanceof Object && !(title instanceof Array)

  return (isLocaleObject ? title[getLocale()] : String(title)) || '—'
}
