<template>
  <div
    :id="`rd-${id}`"
    class="r-dropdown"
    :class="{ opened: isMenuShown }"
    tabindex="-1"
  >
    <div
      ref="dropdown"
      class="r-dropdown__dropdown"
      @click="toggleShown"
    >
      <slot />
    </div>
    <portal
      v-if="isMenuShown"
      to="main-portal"
    >
      <transition name="unroll">
        <div
          ref="menu"
          tabindex="0"
          class="r-dropdown__menu"
          :style="{
            width: width ? `${width}px` : 'auto',
            maxWidth: `${menuMaxWidth}px`,
            maxHeight: `${menuMaxHeight}px`,
            [stickTo]: `${left || right}`,
            top: `${top}px`,
            bottom: `${bottom}px`,
            minWidth: `${minWidth}px`,
            overflow: overflow
          }"
        >
          <r-title
            v-if="menuTitle"
            type="subtitle-2"
            class="r-dropdown__title"
          >
            {{ menuTitle }}
          </r-title>

          <slot
            v-if="isMenuShown"
            name="dropdown-menu"
            class="r-dropdown__menu-list"
          />
        </div>
      </transition>
    </portal>
  </div>
</template>

<script>
import eventBus, { eventNames } from 'EVENT_BUS'
const { MAP_CLOSE_OBJECT } = eventNames

export default {
  props: {
    show: {
      type: Boolean,
      default: null
    },
    menuTitle: {
      type: String,
      default: ''
    },
    menuMaxWidth: {
      type: Number,
      default: null
    },
    menuMaxHeight: {
      type: Number,
      default: 250
    },
    width: {
      type: Number,
      default: null
    },
    stickTo: {
      type: String,
      default: 'left'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    overflow: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      id: null,
      top: null,
      left: null,
      right: null,
      bottom: null,
      minWidth: null,
      menuOnTop: false,
      isMenuShown: false
    }
  },
  watch: {
    show(val) {
      if (val === false) {
        this.emitClose()
      }
    }
  },
  created() {
    this.id = (+new Date()).toString(16)
    this.updateMenuCoords()
    document.addEventListener('click', this.closeMenu, true)
    document.addEventListener('scroll', this.closeMenu, true)
    window.addEventListener('resize', this.closeMenu, true)
    eventBus.on(MAP_CLOSE_OBJECT, this.closeMenu)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeMenu, true)
    document.removeEventListener('scroll', this.closeMenu, true)
    window.removeEventListener('resize', this.closeMenu, true)
    eventBus.off(MAP_CLOSE_OBJECT, this.closeMenu)

    if (this.show !== null) {
      this.emitClose()
    }
  },
  methods: {
    toggleShown() {
      if (this.disabled) return

      this.updateMenuCoords()
      this.isMenuShown = !this.isMenuShown
      this.$emit('toggle', this.isMenuShown)
    },
    hideDropDown() {
      if (!this.isMenuShown) return
      this.isMenuShown = false
      this.$emit('toggle', false)
    },
    closeMenu(e) {
      if (!this.isMenuShown) return

      if (e?.type === 'resize' || (!e?.target && !e?.type)) {
        this.hideDropDown()

        return
      }

      if (
        e.target.closest('.r-dropdown__dropdown') &&
        e.target.closest(`#rd-${this.id}`) &&
        !e.target.closest('.r-dropdown')?.classList.contains('opened') &&
        !e.target.closest('.keep-this-class-opened')
      ) {
        this.hideDropDown()

        return
      }

      if (e.target.closest('.r-dropdown__dropdown')) {
        if (
          !e.target.closest(`#rd-${this.id}`) &&
          !e.target.closest('.r-range-picker')
        ) {
          this.hideDropDown()
        }

        return
      }

      if (
        !e.target.closest('.r-datepicker__table') &&
        !e.target.closest('.r-dropdown__menu') &&
        !e.target.closest('.r-dropdown__dropdown') &&
        !e.target.closest('.select-menu') &&
        !e.target.closest('.r-select-option') &&
        !e.target.closest('.keep-this-class-opened')
      ) {
        this.hideDropDown()

        return
      }
    },
    updateMenuCoords() {
      if (!this.$refs.dropdown) return

      const GAP = 8

      const rect = this.$refs.dropdown.getBoundingClientRect()
      const windowHeight = document.documentElement.clientHeight
      const windowWidth = document.documentElement.clientWidth
      const menuOnTop =
        windowHeight - rect.bottom < this.menuMaxHeight &&
        rect.top > windowHeight - rect.bottom

      this.minWidth = rect.width

      if (menuOnTop) {
        this.bottom = windowHeight - rect.top + GAP
        this.top = null
      } else {
        this.top = rect.bottom + GAP
        this.bottom = null
      }

      switch (this.stickTo) {
        case 'right': {
          this.left = null

          if (rect.right + GAP < this.menuMaxWidth) {
            this.right = windowWidth - this.menuMaxWidth - GAP + 'px'
          } else {
            this.right = `calc(100vw - ${rect.right}px)`
          }

          break
        }

        case 'left': {
          this.right = null

          const localLeft = rect.left

          if (windowWidth - rect.right + rect.width < this.menuMaxWidth) {
            this.left = `${windowWidth - this.menuMaxWidth - GAP}px`
          } else {
            this.left = `${localLeft}px`
          }

          break
        }
      }
    },
    emitClose() {
      this.isMenuShown = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.r-dropdown {
  /* width: fit-content; */
  display: grid;
  width: auto;

  &:empty {
    display: none;
  }

  &__dropdown {
    /* display: flex; */
    z-index: 100;
    /* width: fit-content; */
  }

  &__menu {
    background: $modal-bg;
    position: absolute;
    box-shadow: $box-shadow-s;
    border-radius: 4px;
    border: 1px solid $modal-overlay;
    min-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    width: max-content;
    z-index: 3000;
  }

  &__menu-list {
    padding: 0 0 0.5rem;
  }

  &__title {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
}

.unroll-enter-active,
.unroll-leave-active {
  transition: opacity 0.1s ease;
  overflow: hidden;
  display: block;
  max-height: 250px;
  opacity: 1;
}

.unroll-enter,
.unroll-leave-to {
  max-height: 0;
  opacity: 0;
}
</style>
