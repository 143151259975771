import i18n from '@/extensions/i18n'
import vue from 'vue'

const warnMessages = [
  'no-data',
  'required_fields_not_filled',
  'no_changed_data',
  'check_input',
  // for drawing
  'select_geom',
  // not for drawing
  'no_geom',
  'layer_is_blocked'
]

export const sendNotify = (arg, id) => {
  setImmediate(() => {
    if (typeof arg !== 'string') {
      vue.$notify({
        type: arg?.type || 'info',
        title: arg?.title || i18n.t(arg?.type || 'info'),
        message: arg?.message || ''
      })

      return
    }

    if (warnMessages.includes(arg)) {
      vue.$notify({
        type: 'warning',
        title: i18n.t('warning'),
        message: i18n.t(arg)
      })

      return
    }

    switch (arg) {
      case 'delete':
      case 'save':
        vue.$notify({
          type: 'success',
          title: i18n.t('success'),
          message: id
            ? `${i18n.t('object')} №${id} ${i18n.t(`notify:${arg}`)}`
            : `${i18n.t('object')} ${i18n.t(`notify:${arg}`)}`,
          link: null
        })
        break
      case 'error':
        vue.$notify({
          type: 'error',
          title: i18n.t('error'),
          message: `${i18n.t(`notify:${arg}`)}`
        })
        break
    }
  })
}
