import vue from 'vue'
import { structure, nonClearableFields } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getTsoddActiveLayers: state => state.activeLayers,
    getProjectId: state => state.project.id
  },
  mutations: {
    TSODD_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    TSODD_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        state[field] = value
      }
    },
    TSODD_SPLICE(state, [field, value]) {
      const index = state[field].indexOf(value)

      if (index >= 0) state[field].splice(index, 1)
    },
    TSODD_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    TSODD_ADD_OBJECT_KEY(state, [field, { key, value }]) {
      state[field][key] = value
    },
    TSODD_DELETE_OBJECT_KEY(state, [field, { key }]) {
      delete state[field][key]
    }
  }
}
