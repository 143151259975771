<template>
  <div
    class="r-select"
    :class="{
      error,
      disabled,
      opened: isMenuOpened,
      'dropdown-style': dropdownStyle,
      'show-all': showAllSelectedItems
    }"
  >
    <r-text
      v-if="label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>
    <div
      ref="wrapper"
      v-loading="loading && !filteredOptions.length"
      class="r-select__input-wrapper"
      tabindex="0"
      :class="{ angular }"
      :style="{ maxWidth: maxWidth, width: `${width}px` }"
      @click="inputClickHandler"
      @mouseenter="isClearButtonShowing = true"
      @mouseleave="isClearButtonShowing = false"
    >
      <div
        v-if="!hasNoTextData"
        :class="[
          'r-select__selected-items',
          { 'r-select__selected-items--no-crop': !cropMultipleSelected }
        ]"
      >
        <r-text
          v-for="(id, i) in showedItems"
          :key="i"
          :size="12"
          class="r-select__selected-item"
        >
          <span class="r-select__selected-item-text">
            {{ getOptionTitleById(id) }}
          </span>

          <r-icon
            v-if="!getOptionDisabledById(id)"
            class="r-select__selected-delete"
            name="close-delete"
            :size="15"
            @click.stop.native="deleteSelectedItem(id)"
          />
        </r-text>

        <!-- Active items counter -->
        <div
          v-if="multiple && active?.length > 2 && !showAllSelectedItems"
          class="r-select__selected-item"
        >
          <r-text
            class="r-select__selected-item-more"
            :size="12"
            color-type="primary"
          >
            {{ `+${active?.length - 2}` }}
          </r-text>
        </div>

        <!-- Label -->
        <r-text
          v-if="cacheLabel"
          class="r-select__text-ellipsis"
        >
          {{ cacheLabel }}
        </r-text>

        <!-- Placeholder -->
        <r-text
          v-else-if="placeholder && !active?.length"
          class="r-select__text-ellipsis"
          color-type="field-placeholder"
        >
          {{ $t(placeholder) }}
        </r-text>
      </div>

      <render-option
        v-else-if="selectedOption"
        :dom="selectedOption.html[0].componentOptions.children"
      />

      <r-button
        v-if="loading"
        class="r-select__clear-button"
        loading
        simple
        mini
      />

      <r-button
        v-else-if="isClearButton"
        class="r-select__clear-button"
        icon="clear-input"
        simple
        mini
        @click.native.stop="clearButtonClickHandler"
      />

      <r-button
        v-else
        class="r-select__dropdown-icon"
        mini
        simple
        tabindex="-1"
        :disabled="disabled"
        :dropdown="dropdownStyle"
        :type="dropdownStyle ? 'primary' : 'default'"
        :icon="{ name: dropdownStyle ? '' : 'chevron-down', size: 16 }"
        @click.native.stop="toggleMenu"
      />
    </div>

    <portal
      v-if="
        (isMenuOpened && !loading) ||
        (isMenuOpened && (filteredOptions.length || text))
      "
      to="main-portal"
    >
      <transition
        name="unroll"
        mode="in-out"
      >
        <select-menu
          ref="menu"
          :key="menuTop"
          :text="text"
          :mode="mode"
          :top="menuTop"
          :left="menuLeft"
          :active="active"
          :width="menuWidth"
          :loading="loading"
          :bottom="menuBottom"
          :disabled="disabled"
          :multiple="multiple"
          :qty-page="currentPage"
          :alias_key="alias_key"
          :cache-query="cacheQuery"
          :total-count="totalCount"
          :can-be-empty="canBeEmpty"
          :no-select-all="noSelectAll"
          :is-filterable="isFilterable"
          :has-no-text-data="hasNoTextData"
          :filtered-options="filteredOptions"
          :service-options="!!initialServiceHandler"
          :cache-display-options="cacheDisplayOptions"
          :cache-endpoint-options="cacheEndpointOptions"
          @select-all="selectAll"
          @show-more="showMoreHandler"
          @item-click="itemClickHandler"
          @filter-input="filterInputHandler"
        />
      </transition>
    </portal>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'selected',
    event: 'change'
  }
}
</script>

<script setup>
import {
  defineAsyncComponent as DAC,
  onBeforeUnmount,
  onBeforeMount,
  onMounted,
  computed,
  useSlots,
  nextTick,
  watch,
  ref
} from 'vue'
import { cloneDeep, debounce, generateId } from 'HELPERS'
import eventBus, { eventNames } from 'EVENT_BUS'
import { GET } from 'API'
import { useLoading } from 'COMPOSITIONS/loading'
import $store from 'STORE'

import { getOptionTitle } from './r-select/helpers'

const { SELECT_OPEN } = eventNames
const { loading, toggleLoading } = useLoading()
const slots = useSlots()

const selectMenu = DAC(() => import('./r-select/components/select-menu'))
const renderOption = DAC(() => import('./r-select/components/render-option'))

// eslint-disable-next-line no-undef
const $emit = defineEmits(['change'])

// eslint-disable-next-line no-undef
const props = defineProps({
  selected: {
    type: [String, Array, Number],
    default: null
  },
  options: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: 'select_item'
  },
  filterable: {
    type: Boolean,
    default: false
  },
  clearable: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  },
  angular: {
    type: Boolean,
    default: false
  },
  dropdownStyle: {
    type: Boolean,
    default: false
  },
  showAllSelectedItems: {
    type: Boolean,
    default: false
  },
  maxWidth: {
    type: String,
    default: '100%'
  },
  label: {
    type: String,
    default: ''
  },
  width: {
    type: Number,
    default: null
  },
  mode: {
    // ellipsis, tooltip, wrap
    type: String,
    default: 'ellipsis'
  },
  cropMultipleSelected: {
    type: Boolean,
    default: true
  },
  serviceHandler: {
    type: Function,
    default: null
  },
  serviceOptions: {
    type: Object,
    default: null
  },
  step: {
    type: Number,
    default: 20
  },
  canBeEmpty: {
    type: Boolean,
    default: false
  }
})

let initialServiceHandler = null

// -----*-----*-----*-----*----- refs -----*-----*-----*-----*-----*-----*-----*

const uid = ref(null)
const active = ref(props.selected)
const text = ref('')
const menuTop = ref(null)
const menuLeft = ref(null)
const menuBottom = ref(null)
const menuWidth = ref(null)
const slotOptions = ref(null)
const cacheLabel = ref(null)
const totalCount = ref(0)
const currentPage = ref(1)
const cacheQuery = ref('')
const cacheQuery2 = ref('')
const isMenuOpened = ref(false)
const hasNoTextData = ref(false)
const endpointOptions = ref([])
const endpointDataCache = ref({})
const cacheDisplayOptions = ref([])
const cacheEndpointOptions = ref([])
const isClearButtonShowing = ref(false)

// -----*-----*-----*-----*----- $refs -----*-----*-----*-----*-----*-----*-----*

const wrapper = ref(null)

// -----*-----*-----*-----*----- computed -----*-----*-----*-----*-----*-----*--

const locale = computed(() => $store.getters.getLocale)
const alias_key = computed(() => props.serviceOptions?.relation?.alias_key)

const opt = computed(() => {
  if (initialServiceHandler) {
    return endpointOptions.value
  } else if (slots.default) {
    return slotOptions.value
  } else {
    return props.options
  }
})
const isFilterable = computed(() => {
  return props.filterable || !!initialServiceHandler
})
const filteredOptions = computed(() => {
  if (initialServiceHandler) return opt.value

  if (!isFilterable.value || !text.value) return opt.value

  const query = text.value?.toLowerCase()

  if (query?.trim()) {
    return opt.value.filter(item => {
      const name = getOptionTitle(item, alias_key.value)

      return name?.toLowerCase().includes(query)
    })
  }

  return opt.value
})
const isClearButton = computed(() => {
  if (props.disabled) return false

  return (
    props.clearable &&
    hasActive.value &&
    (isClearButtonShowing.value || isMenuOpened.value)
  )
})
const showedItems = computed(() => {
  if (!props.multiple || !active.value) return []

  return props.showAllSelectedItems ? active.value : active.value.slice(0, 2)
})
const selectedOption = computed(() => {
  if (props.multiple) {
    return opt.value?.find(item => item.id === props.selected[0])
  } else {
    return opt.value?.find(item => item.id === props.selected)
  }
})
const noSelectAll = computed(() => !!initialServiceHandler)
const hasActive = computed(() => {
  return (
    (Array.isArray(active.value) && active.value?.length) ||
    typeof active.value === 'number' ||
    (typeof active.value === 'string' && active.value)
  )
})

// -----*-----*-----*-----*----- methods -----*-----*-----*-----*-----*-----*---

const filterInputHandler = debounce(val => {
  text.value = val

  const isSendRequest = val?.trim() !== cacheQuery2.value?.trim()

  if (!isSendRequest && !cacheQuery2.value?.trim()) return

  if (!val && !hasActive.value) {
    endpointOptions.value = []
    cacheEndpointOptions.value = []
  }

  if (initialServiceHandler) {
    currentPage.value = 1

    if (!val) {
      resetSearchFilter()
    }

    loadServiceData({ reset: !val })
  }

  cacheQuery2.value = val
}, 256)

const inputClickHandler = () => {
  if (props.disabled) return

  toggleMenu()
}

const itemClickHandler = (id, name) => {
  if (!id && id !== 0) {
    isMenuOpened.value = false

    return
  }

  const item = opt.value?.find(item => String(item.id) === String(id))

  if (!props.multiple || hasNoTextData.value) {
    if (
      item.id === active.value ||
      (hasNoTextData.value && props.multiple && active.value?.includes(item.id))
    ) {
      // no need to change value
      isMenuOpened.value = false

      return
    }

    if (initialServiceHandler && !endpointDataCache.value[id]) {
      if (!endpointDataCache.value[id]) {
        endpointDataCache.value[id] = item
      }

      removeEndpointCacheItem(active.value)
    }

    active.value = props.multiple ? [item.id] : item.id
    text.value = cacheLabel.value = getOptionTitle(item, alias_key.value)
    isMenuOpened.value = false
  } else {
    active.value = active.value || []
    wrapper.value?.focus()

    const index = active.value.indexOf(item.id)

    if (index < 0) {
      if (!active.value) {
        active.value = [item.id]
      } else {
        active.value.push(item.id)
      }

      if (initialServiceHandler && !endpointDataCache.value[id]) {
        endpointDataCache.value[id] = item
      }
    } else {
      active.value = active.value?.filter(el => el !== item.id)
      removeEndpointCacheItem(id)
    }
  }

  nextTick(updateMenuCoords)
  $emit('change', active.value, name, item)
}

const toggleMenu = () => {
  if (props.disabled) return

  isMenuOpened.value = !isMenuOpened.value

  if (isMenuOpened.value) {
    currentPage.value = 1
    resetSearchFilter()
    loadServiceData()
    updateMenuCoords()

    if (initialServiceHandler && !hasActive.value) {
      endpointOptions.value = []
    }
  } else {
    cacheQuery2.value = ''
  }

  if (!props.multiple && isFilterable.value) {
    text.value = null
  }

  eventBus.emit(SELECT_OPEN, uid.value)
}

const hideByOtherSelect = id => {
  if (id !== uid.value) {
    isMenuOpened.value = false

    if (text.value && props.multiple) {
      text.value = null
    }

    if (!props.multiple) {
      const value = opt.value?.find(item => item.id === active.value)

      if (initialServiceHandler && !value) {
        const cacheOption = endpointDataCache.value[active.value]
        text.value = cacheOption?.name || active.value
      } else {
        text.value = getOptionTitle(value, alias_key.value)
      }
    }
  }
}

const clearButtonClickHandler = () => {
  if (props.disabled) return

  resetSearchFilter()
  loadServiceData({ reset: true })

  if (isFilterable.value) {
    isMenuOpened.value = true
  }

  active.value = !props.multiple ? null : []

  nextTick(updateMenuCoords)

  $emit('change', active.value)

  eventBus.emit(SELECT_OPEN, uid.value)
}

const closeMenu = e => {
  e?.stopPropagation()

  if (!isMenuOpened.value) return

  if (e === undefined || e?.type === 'resize') {
    isMenuOpened.value = false

    return
  }

  /**
   * called on an input that has lost focus, or when scrolling, or click outside
   * for @mouseup and scroll. Checks that click was inside select
   */
  const clickOutside =
    !e?.target.closest('.select-menu__menu-item') &&
    !e?.target.closest('.r-select__input-wrapper') &&
    !e?.target.closest('.r-select__dropdown-icon') &&
    !e?.target.closest('.select-menu')

  if (clickOutside) {
    if (!props.multiple) {
      const value = opt.value?.find(item => item.id === active.value)

      if (initialServiceHandler && !value) {
        const cacheOption = endpointDataCache.value[active.value]
        text.value = cacheOption?.name || active.value
      } else {
        text.value = getOptionTitle(value, alias_key.value)
      }
    } else {
      text.value = null
    }

    isMenuOpened.value = false
  }
}

const debounceCloseMenu = debounce(closeMenu, 64)

const updateMenuCoords = () => {
  if (!wrapper.value) return

  menuTop.value = null
  menuBottom.value = null

  const SELECT_MENU_HEIGHT = 200
  const SEARCH_HEIGHT = 60
  const GAP = 16

  const wrapperNodeRect = wrapper.value.getBoundingClientRect()
  const windowHeight = document.documentElement.clientHeight
  const menuOnTop =
    wrapperNodeRect.bottom >
    windowHeight - SELECT_MENU_HEIGHT - SEARCH_HEIGHT - GAP

  if (menuOnTop) {
    menuBottom.value = windowHeight - wrapperNodeRect.top + 8
    menuTop.value = null
  } else {
    menuTop.value = wrapperNodeRect.bottom + (props.dropdownStyle ? 8 : 4)
    menuBottom.value = null
  }

  menuLeft.value = wrapperNodeRect.left
  menuWidth.value = wrapperNodeRect.width
}

const getOptionTitleById = id => {
  const option = opt.value?.find(item => item.id === id)

  if (initialServiceHandler && !option) {
    const cacheOption = endpointDataCache.value[id]

    return getOptionTitle(cacheOption, alias_key.value) || id
  }

  return !option ? id : getOptionTitle(option, alias_key.value)
}

const getOptionDisabledById = id => {
  const option = opt.value?.find(item => item.id === id)

  if (option) return option?.disabled

  if (initialServiceHandler) return endpointDataCache.value[id]?.disabled

  return false
}

const deleteSelectedItem = id => {
  if (props.disabled || opt.value?.find(item => item.id === id)?.disabled)
    return

  active.value = active.value?.filter(item => item !== id)

  removeEndpointCacheItem(id)
  $emit('change', active.value)

  if (isMenuOpened.value) {
    nextTick(updateMenuCoords)
  }
}

const removeEndpointCacheItem = id => {
  if (endpointDataCache.value[id]) {
    delete endpointDataCache.value[id]
  }
}

const selectAll = () => {
  active.value = active.value || []

  const allEnabledOptionsSelected = opt.value
    .filter(option => !option.disabled)
    .map(item => item.id)
    .every(option => active.value?.includes(option))

  const disabledSelectedOptionsIds = opt.value
    .filter(option => option.disabled)
    .map(disabledOption => disabledOption.id)
    .filter(id => active.value?.includes(id))

  if (allEnabledOptionsSelected) {
    active.value = disabledSelectedOptionsIds
  } else {
    opt.value
      .filter(item => !item.disabled)
      .forEach(option => {
        if (active.value?.indexOf(option.id) === -1) {
          active.value?.push(option.id)
        }
      })
  }

  $emit('change', active.value)
  nextTick(updateMenuCoords)
}

const loadServiceData = async (options = {}) => {
  if ((options?.isCache && !hasActive.value) || !initialServiceHandler) return

  try {
    toggleLoading(true)

    const isObject = obj =>
      Object.prototype.toString.call(obj) === '[object Object]'

    const queryWithoutExtraSpaces = text.value
      ?.toLowerCase()
      .replace(/ +/g, ' ')

    const params = {
      step: props.step,
      page: options.page || 1
    }

    if (text.value) {
      params.query = queryWithoutExtraSpaces
    }

    if (options.isCache) {
      params.selected = active.value
      params.init = true
    } else {
      cacheQuery.value = queryWithoutExtraSpaces
    }

    if (options.reset) {
      delete params.query
      delete params.selected
      params.page = 1
    }

    const { data: response } = await initialServiceHandler(params)

    if (typeof response === 'string') return

    totalCount.value = response?.total || 0

    if (response) {
      if (isObject(response)) {
        if (response?.data) {
          if (params.page > 1) {
            endpointOptions.value.push(...response.data)
          } else {
            endpointOptions.value = response.data
          }
        } else {
          endpointOptions.value = []
        }
      } else {
        endpointOptions.value = response
      }
    } else {
      endpointOptions.value = []
    }

    if (props.multiple) {
      cacheDisplayOptions.value.push(...endpointOptions.value)
    }

    if (options?.isCache) {
      cacheEndpointOptions.value = cloneDeep(endpointOptions.value)

      endpointOptions.value.forEach(item => {
        endpointDataCache.value[item.id] = item
      })
    }
  } catch (e) {
    throw e
  } finally {
    toggleLoading(false)
  }
}

const showMoreHandler = async () => {
  currentPage.value++

  await loadServiceData({
    page: currentPage.value
  })
}

const resetSearchFilter = () => {
  currentPage.value = 1
  cacheQuery.value = ''
  text.value = ''
}

const selectServiceHandler = async ({ query, selected, step, page }) => {
  try {
    const { region, relation } = props.serviceOptions
    const { endpoint, alias_key } = relation
    const op = Array.isArray(selected) ? 'in' : '=='

    const parameters = {
      order_by: { field: alias_key, type: 'asc' },
      fields: ['id', alias_key],
      limit: step,
      page
    }

    const hasSelected =
      (Array.isArray(selected) && selected?.length) ||
      typeof selected === 'number'

    if (query || hasSelected) {
      parameters.attribute = hasSelected
        ? [{ field: 'id', op, value: selected }]
        : [{ field: alias_key, op: 'like', value: query }]
    }

    const params = {
      parameters,
      as_array: true
    }

    if (region) {
      params.region = region
    }

    return await GET(endpoint, params)
  } catch (e) {
    throw e
  }
}

const initServiceHandler = () => {
  const { serviceHandler, serviceOptions } = props

  if (serviceHandler) {
    initialServiceHandler = serviceHandler
  } else if (serviceOptions) {
    initialServiceHandler = selectServiceHandler
  } else {
    initialServiceHandler = null
  }
}

// -----*-----*-----*-----*----- hooks -----*-----*-----*-----*-----*-----*-----

onBeforeMount(() => {
  initServiceHandler()

  const selected = props.options.find(item => item.id === props.selected)

  text.value = props.multiple ? '' : getOptionTitle(selected, alias_key.value)
  uid.value = generateId()

  if (
    !!props.multiple !== !!Array.isArray(props.selected) &&
    props.selected !== null
  ) {
    console.warn('Wrong type of selected prop. Correct type: array')
  }

  loadServiceData({ isCache: true })
})

onMounted(() => {
  if (slots.default) {
    hasNoTextData.value = slots.default().some(child => {
      const firstChild = child?.componentOptions?.children?.[0]

      return (
        child.componentOptions.children?.length > 1 ||
        !(firstChild?.componentOptions?.tag === 'r-text' || firstChild?.text)
      )
    })

    const getTextFromSlotNode = node => {
      let text = node.text || ''

      if (node.componentOptions?.children?.length) {
        text += node.componentOptions.children
          .map(getTextFromSlotNode)
          ?.filter(e => e)
          ?.join(', ')
      }

      return text?.trim()
    }

    const getTextFromOptions = node =>
      props.options.find(item => item.id === node.data.key)?.alias?.[
        locale.value
      ]

    slotOptions.value = slots.default().map(child => {
      const title =
        getTextFromSlotNode(child) || getTextFromOptions(child) || null

      return {
        id: child.componentOptions.propsData.id,
        disabled: child.componentOptions.propsData.disabled,
        title: title ? title.trim() : null,
        html: hasNoTextData.value ? [child] : null
      }
    })

    const value = opt.value?.find(item => item.id === active.value)
    text.value = getOptionTitle(value, alias_key.value)
  }

  if (!props.multiple) {
    cacheLabel.value = text.value
  }

  updateMenuCoords()

  window.addEventListener('scroll', debounceCloseMenu, true)
  window.addEventListener('resize', debounceCloseMenu)
  window.addEventListener('mouseup', closeMenu)
  window.addEventListener('click', closeMenu)

  eventBus.on(SELECT_OPEN, hideByOtherSelect)
})

onBeforeUnmount(() => {
  closeMenu()

  window.removeEventListener('scroll', debounceCloseMenu)
  window.removeEventListener('resize', debounceCloseMenu)
  window.removeEventListener('mouseup', closeMenu)
  window.removeEventListener('click', closeMenu)

  eventBus.off(SELECT_OPEN, hideByOtherSelect)
})

// -----*-----*-----*-----*----- watchers -----*-----*-----*-----*-----*-----*--

watch(
  () => props.options,
  () => {
    if (!props.multiple) {
      const option = opt.value?.find(item => item.id === props.selected)
      text.value = cacheLabel.value = getOptionTitle(option, alias_key.value)
    }
  }
)

watch(
  () => props.selected,
  value => {
    active.value = value

    if (!props.multiple) {
      const option = opt.value?.find(item => item.id === value)
      text.value = cacheLabel.value = getOptionTitle(option, alias_key.value)
    }
  }
)

watch(endpointOptions, options => {
  if (options?.length && !props.multiple) {
    const option = options.find(item => item.id === active.value)

    if (option) {
      cacheLabel.value = getOptionTitle(option, alias_key.value)
    }
  }
})

watch(() => props.serviceHandler, initServiceHandler)
</script>

<style lang="scss" scoped>
.r-select {
  $self: &;

  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 0.25rem;

  /**
   * Style
   */

  &.dropdown-style {
    .r-select__input-wrapper {
      background: $accent-primary-1;
      border: none;
    }

    .r-select__input {
      font-weight: 600;
      color: $button-primary-bg !important;
    }
  }

  &__text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  /**
   * State
   */

  &.show-all {
    .r-select__selected-item {
      max-width: 128px;
    }

    .r-select__selected-items {
      flex-wrap: wrap;
    }
  }

  &.disabled {
    .r-select__input {
      cursor: not-allowed;
    }

    // .r-select__input-wrapper {
    //   opacity: 0.4;
    // }

    .r-select__input-wrapper {
      cursor: not-allowed;
    }
  }

  &.opened {
    .r-select__dropdown-icon {
      transform: scale(1, -1);
    }

    .r-select__input-wrapper {
      border-color: $field-active-border;
    }
  }

  &.error {
    .r-select__input-wrapper {
      border-color: $accent-danger;
    }
  }

  /**
   * Elements
   */
  &__input-wrapper {
    flex: 1;
    display: flex;
    gap: 4px;
    position: relative;
    background: $field-bg;
    cursor: pointer;
    align-items: center;
    padding: 0.25rem 2.5rem 0.25rem 0.5rem;
    border-radius: $border-radius;
    border: 1px solid $field-border;
    min-height: 36px;
    transition: border 0.25s ease;

    &.angular {
      border-radius: 0;
    }
  }

  &__selected-items {
    display: flex;
    gap: 4px;
    align-items: center;
    width: 100%;
    overflow: hidden;

    &--no-crop {
      flex-wrap: wrap;

      #{$self}__selected-item {
        max-width: 100% !important;
      }
    }
  }

  &__selected-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    flex-shrink: 0;
    border: 1px solid $dividers-high-contrast;
    border-radius: $border-radius;
    padding: 0.25rem;
    max-width: 128px;
    line-height: 1rem;

    &-more {
      line-height: 1rem;
    }

    &-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__selected-delete {
    flex-shrink: 0;
    line-height: 1;
    height: auto;
  }

  &__clear-button {
    position: absolute;
    right: 0.25rem;
    top: calc(50% - 14px);
  }

  &__dropdown-icon {
    position: absolute;
    right: 0.25rem;
    top: calc(50% - 14px);
    transform: scale(1);
  }

  &__no-text-data-wrapper {
    display: flex;
    align-items: center;
  }
}

/**
 * Animation
 */
.unroll-enter-active,
.unroll-leave-active {
  transition: all 0.1s ease;
  overflow: hidden;
  display: block;
  max-height: 250px;
  opacity: 1;
}

.unroll-enter,
.unroll-leave-to {
  max-height: 0;
  opacity: 0.5;
}
</style>
