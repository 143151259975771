<template>
  <div class="r-input-time">
    <r-text v-if="label">
      {{ label }}
    </r-text>
    <div
      class="r-input-time__body"
      :class="{ error: error || notValid, disabled, clearable }"
    >
      <r-icon
        v-if="icon"
        name="in-process"
      />

      <div
        v-if="timeFormat !== 'mm:ss'"
        class="r-input-time__body-input-left"
      >
        <input
          ref="hoursRef"
          class="r-input-time__body-input"
          :value="valueHours"
          :placeholder="placeholder ? $t('hh') : ''"
          :disabled="disabled"
          @input="inputHours"
          @keydown.backspace="handleBackspace"
          @keydown.left="handleLeft"
          @keydown.right="handleRight"
          @blur="blur"
        />
      </div>

      <input
        ref="minutesRef"
        class="r-input-time__body-input"
        :value="valueMinutes"
        :placeholder="placeholder ? $t('mm') : ''"
        :disabled="disabled"
        @input="inputMinutes"
        @keydown.backspace="handleBackspace"
        @keydown.left="handleLeft"
        @keydown.right="handleRight"
        @blur="blur"
      />

      <div
        v-if="timeFormat !== 'hh:mm'"
        class="r-input-time__body-input-right"
      >
        <input
          ref="secondsRef"
          class="r-input-time__body-input"
          :value="valueSeconds"
          :placeholder="placeholder ? $t('ss') : ''"
          :disabled="disabled"
          @input="inputSeconds"
          @keydown.backspace="handleBackspace"
          @keydown.left="handleLeft"
          @keydown.right="handleRight"
          @blur="blur"
        />
      </div>

      <r-button
        v-if="clearable"
        class="r-input-time__body-clear"
        simple
        icon="clear-input"
        mini
        @click.prevent.stop="emit('input', null)"
      />
    </div>
  </div>
</template>

<script setup>
import { isNumber } from 'HELPERS'
import { computed, ref } from 'vue'
// eslint-disable-next-line no-undef
const emit = defineEmits(['input'])

// eslint-disable-next-line no-undef
const props = defineProps({
  value: {
    type: [String, Number],
    default: '00:00'
  },
  placeholder: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  icon: {
    type: Boolean,
    default: false
  },
  min: {
    type: String,
    default: null
  },
  max: {
    type: String,
    default: null
  },
  offTimeLimit: {
    type: Boolean,
    default: false
  },
  timeFormat: {
    type: String,
    default: 'hh:mm'
  },
  clearable: {
    type: Boolean,
    default: false
  }
})

const hoursRef = ref(null)
const minutesRef = ref(null)
const notValid = ref(false)

const secondsRef = ref(null)

const valueHours = computed(() => {
  const arr = props.value?.split(':')

  if (!arr) return

  if (props.timeFormat !== 'mm:ss') {
    return (arr?.length > 1 ? arr[0] : props.value)?.replace(/[^0-9]/g, '')
  } else {
    return null
  }
})

const valueMinutes = computed(() => {
  const arr = props.value?.split(':')

  if (!arr) return

  if (props.timeFormat === 'mm:ss') {
    return (arr?.length > 1 ? arr[0] : props.value)?.replace(/[^0-9]/g, '')
  } else {
    return arr?.length > 1 ? arr[1]?.replace(/[^0-9]/g, '') : null
  }
})

const valueSeconds = computed(() => {
  const arr = props.value?.split(':')

  if (!arr) return

  if (props.timeFormat !== 'hh:mm') {
    return (arr?.length > 2 ? arr[2] : arr[1])?.replace(/[^0-9]/g, '')
  } else {
    return null
  }
})

const getMinutes = str => {
  let minutes = str.slice(0, 2)
  let seconds

  if (str.length > 2) {
    seconds = str.slice(2)
  }

  if (minutes > 59) {
    minutes = str.slice(0, 1)

    if (props.timeFormat !== 'hh:mm') {
      seconds = str.slice(1)
      secondsRef.value.value = seconds
      secondsRef.value.focus()
    }
  }

  return [minutes, seconds]
}

const getSeconds = str => {
  let seconds = str.slice(0, 2)

  if (seconds > 59) {
    seconds = str.slice(0, 1)
  }

  return seconds
}

const getFullTime = () => {
  let formattedTime = ''

  if (!hoursRef.value?.value && !minutesRef.value.value) return null

  if (props.timeFormat === 'hh:mm:ss') {
    formattedTime =
      hoursRef.value.value +
      ':' +
      minutesRef.value.value +
      ':' +
      secondsRef.value.value
  }

  if (props.timeFormat === 'mm:ss') {
    formattedTime = minutesRef.value.value + ':' + secondsRef.value.value
  }

  if (props.timeFormat === 'hh:mm') {
    formattedTime = hoursRef.value.value + ':' + minutesRef.value.value
  }

  return formattedTime
}

const format = (hh, mm) => {
  const str = mm === null ? hh : hh + mm

  let hours = str.slice(0, 2)
  let index = 2

  if (hours > 23) {
    hours = str.slice(0, 1)
    index = 1
  }

  const minutes = getMinutes(str.slice(index, index + 2))

  return [hours, minutes]
}

const check = () => {
  const arrMin = props.min?.split(':')
  const arrMax = props.max?.split(':')
  let error = false

  if (
    (!props.min && !props.max) ||
    // hoursRef.value?.value.length < 2 ||
    minutesRef.value?.value.length < 2 ||
    // secondsRef.value?.value.length < 2 ||
    (arrMin?.length < 2 && arrMax?.length < 2)
  ) {
    notValid.value = false

    return
  }

  if (arrMin?.length >= 2) {
    const minH = arrMin[0]
    const minM = arrMin[1]
    const minS = arrMin[2]

    error =
      hoursRef.value?.value < minH ||
      (hoursRef.value?.value === minH && minutesRef.value?.value < minM) ||
      (hoursRef.value?.value === minH &&
        minutesRef.value?.value === minM &&
        secondsRef.value?.value < minS)
  }

  if (!error && arrMax?.length >= 2) {
    const maxH = arrMax[0]
    const maxM = arrMax[1]
    const maxS = arrMax[2]

    error =
      hoursRef.value?.value > maxH ||
      (hoursRef.value?.value === maxH && minutesRef.value?.value > maxM) ||
      (hoursRef.value?.value === maxH &&
        minutesRef.value?.value === maxM &&
        secondsRef.value?.value > maxS)
  }

  if (props.timeFormat === 'mm:ss') {
    if (!error && arrMin?.length >= 2) {
      const minM = arrMin[1]
      const minS = arrMin[2]

      error =
        minutesRef.value?.value < minM ||
        (minutesRef.value?.value === minM && secondsRef.value?.value < minS)
    }

    if (!error && arrMax?.length >= 2) {
      const maxM = arrMax[1]
      const maxS = arrMax[2]

      error =
        minutesRef.value?.value > maxM ||
        (minutesRef.value?.value === maxM && secondsRef.value?.value > maxS)
    }
  }

  notValid.value = error
}

const inputHours = event => {
  const end = hoursRef.value.selectionEnd
  let value = event.target.value.replace(/[^0-9]/g, '')

  if ((value.length > 2 || value > 23) && !props.offTimeLimit) {
    const [hours, minutes] = format(value, minutesRef.value.value)
    hoursRef.value.value = hours
    minutesRef.value.value = minutes[0]

    if (minutes.length > 0 && end > 2) minutesRef.value.focus()
    setTimeout(() => {
      minutesRef.value.selectionStart = 1
      minutesRef.value.selectionEnd = 1
    }, 0)
  } else hoursRef.value.value = value

  emit('input', getFullTime())
  check()
}

const inputSeconds = event => {
  secondsRef.value.value = getSeconds(event.target.value.replace(/[^0-9]/g, ''))

  emit('input', getFullTime())
  check()
}

const inputMinutes = event => {
  const end = minutesRef.value.selectionEnd

  const [minutes, seconds] = getMinutes(
    event.target.value.replace(/[^0-9]/g, '')
  )

  minutesRef.value.value = minutes

  if (
    (end === 3 || minutes > 59) &&
    !props.offTimeLimit &&
    props.timeFormat !== 'hh:mm'
  ) {
    secondsRef.value.focus()
    secondsRef.value.value = seconds
  }

  if (end <= 1) {
    minutesRef.value.selectionStart = 1
    minutesRef.value.selectionEnd = 1
  }

  emit('input', getFullTime())
  check()
}

const handleBackspace = e => {
  if (e.target === minutesRef.value && e.target.selectionStart < 1) {
    hoursRef.value.focus()
  }

  if (e.target === secondsRef.value && e.target.selectionStart < 1) {
    minutesRef.value.focus()
  }
}

const handleLeft = e => {
  if (e.target === secondsRef.value && e.target.selectionStart < 1) {
    minutesRef.value.focus()
    setTimeout(() => {
      minutesRef.value.selectionStart += 1
    }, 0)
  }

  if (e.target === minutesRef.value && e.target.selectionStart < 1) {
    hoursRef.value.focus()
    setTimeout(() => {
      hoursRef.value.selectionStart += 1
    }, 0)
  }
}

const handleRight = e => {
  if (
    e.target === hoursRef.value &&
    (!hoursRef.value?.value ||
      e.target.selectionStart > hoursRef.value.value.length - 1)
  ) {
    minutesRef.value.focus()
    setTimeout(() => {
      minutesRef.value.selectionEnd -= 1
    }, 0)
  }

  if (
    e.target === minutesRef.value &&
    (!minutesRef.value?.value ||
      e.target.selectionStart > minutesRef.value.value.length - 1)
  ) {
    secondsRef.value.focus()
    setTimeout(() => {
      secondsRef.value.selectionEnd -= 1
    }, 0)
  }
}

const blur = e => {
  let minutes = minutesRef.value.value
  let hours
  let seconds

  if (props.timeFormat === 'hh:mm:ss') {
    hours = hoursRef.value.value
    minutes = minutesRef.value.value
    seconds = secondsRef.value.value

    if (
      (hours.length > 0 || minutes.length > 0 || seconds.length > 0) &&
      e.relatedTarget !== hoursRef.value &&
      e.relatedTarget !== minutesRef.value &&
      e.relatedTarget !== secondsRef.value
    ) {
      hours = hours.padStart(2, '0')
      minutes = minutes.padStart(2, '0')
      seconds = seconds.padStart(2, '0')

      hoursRef.value.value = hours
      minutesRef.value.value = minutes
      secondsRef.value.value = seconds
    }
  }

  if (props.timeFormat === 'hh:mm') {
    hours = hoursRef.value.value
    minutes = minutesRef.value.value

    if (
      (hours.length > 0 || minutes.length > 0) &&
      e.relatedTarget !== hoursRef?.value &&
      e.relatedTarget !== minutesRef.value
    ) {
      hours = hours.padStart(2, '0')
      minutes = minutes.padStart(2, '0')

      hoursRef.value.value = hours
      minutesRef.value.value = minutes
    }
  }

  if (props.timeFormat === 'mm:ss') {
    seconds = secondsRef.value.value
    minutes = minutesRef.value.value

    if (
      (seconds.length > 0 || minutes.length > 0) &&
      e.relatedTarget !== secondsRef?.value &&
      e.relatedTarget !== minutesRef.value
    ) {
      seconds = seconds.padStart(2, '0')
      minutes = minutes.padStart(2, '0')

      secondsRef.value.value = seconds
      minutesRef.value.value = minutes
    }
  }

  emit('input', getFullTime())
  check()
}
</script>

<style lang="scss" scoped>
.r-input-time {
  @include grid-column(0.25rem);

  &__body {
    position: relative;
    max-width: 130px;
    height: 36px;
    display: flex;
    justify-content: center;
    background: $field-bg;
    padding: 0 0.5rem;
    border: 1px solid $field-border;
    border-radius: $border-radius;
    transition: 0.16s;

    &-clear {
      position: absolute;
      right: 0;
      top: 3px;
      opacity: 0;
      background: transparent !important;
    }

    &:hover {
      .r-input-time__body-clear {
        opacity: 1;
      }
    }

    &-input {
      width: 30px;
      height: 100%;
      background: transparent;
      border: none;
      color: $text-primary;
      text-align: center;

      &::placeholder {
        color: $field-placeholder;
      }

      &:disabled {
        cursor: not-allowed;
      }

      &-left {
        position: relative;
        &::after {
          content: ':';
          position: absolute;
          right: -2px;
          top: 6px;
          color: $text-primary;
        }
      }
      &-right {
        position: relative;
        &::before {
          content: ':';
          position: absolute;
          left: -2px;
          top: 6px;
          color: $text-primary;
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
    &.error {
      border-color: $accent-danger;
    }

    &.clearable {
      min-width: 100px;
    }
  }
}
</style>
