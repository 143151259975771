var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"r-select",class:{
    error: _vm.error,
    disabled: _vm.disabled,
    opened: _setup.isMenuOpened,
    'dropdown-style': _vm.dropdownStyle,
    'show-all': _vm.showAllSelectedItems
  }},[(_vm.label)?_c('r-text',{attrs:{"color-type":"subhead"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_setup.loading && !_setup.filteredOptions.length),expression:"loading && !filteredOptions.length"}],ref:"wrapper",staticClass:"r-select__input-wrapper",class:{ angular: _vm.angular },style:({ maxWidth: _vm.maxWidth, width: `${_vm.width}px` }),attrs:{"tabindex":"0"},on:{"click":_setup.inputClickHandler,"mouseenter":function($event){_setup.isClearButtonShowing = true},"mouseleave":function($event){_setup.isClearButtonShowing = false}}},[(!_setup.hasNoTextData)?_c('div',{class:[
        'r-select__selected-items',
        { 'r-select__selected-items--no-crop': !_vm.cropMultipleSelected }
      ]},[_vm._l((_setup.showedItems),function(id,i){return _c('r-text',{key:i,staticClass:"r-select__selected-item",attrs:{"size":12}},[_c('span',{staticClass:"r-select__selected-item-text"},[_vm._v(" "+_vm._s(_setup.getOptionTitleById(id))+" ")]),(!_setup.getOptionDisabledById(id))?_c('r-icon',{staticClass:"r-select__selected-delete",attrs:{"name":"close-delete","size":15},nativeOn:{"click":function($event){$event.stopPropagation();return _setup.deleteSelectedItem(id)}}}):_vm._e()],1)}),(_vm.multiple && _setup.active?.length > 2 && !_vm.showAllSelectedItems)?_c('div',{staticClass:"r-select__selected-item"},[_c('r-text',{staticClass:"r-select__selected-item-more",attrs:{"size":12,"color-type":"primary"}},[_vm._v(" "+_vm._s(`+${_setup.active?.length - 2}`)+" ")])],1):_vm._e(),(_setup.cacheLabel)?_c('r-text',{staticClass:"r-select__text-ellipsis"},[_vm._v(" "+_vm._s(_setup.cacheLabel)+" ")]):(_vm.placeholder && !_setup.active?.length)?_c('r-text',{staticClass:"r-select__text-ellipsis",attrs:{"color-type":"field-placeholder"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.placeholder))+" ")]):_vm._e()],2):(_setup.selectedOption)?_c(_setup.renderOption,{attrs:{"dom":_setup.selectedOption.html[0].componentOptions.children}}):_vm._e(),(_setup.loading)?_c('r-button',{staticClass:"r-select__clear-button",attrs:{"loading":"","simple":"","mini":""}}):(_setup.isClearButton)?_c('r-button',{staticClass:"r-select__clear-button",attrs:{"icon":"clear-input","simple":"","mini":""},nativeOn:{"click":function($event){$event.stopPropagation();return _setup.clearButtonClickHandler.apply(null, arguments)}}}):_c('r-button',{staticClass:"r-select__dropdown-icon",attrs:{"mini":"","simple":"","tabindex":"-1","disabled":_vm.disabled,"dropdown":_vm.dropdownStyle,"type":_vm.dropdownStyle ? 'primary' : 'default',"icon":{ name: _vm.dropdownStyle ? '' : 'chevron-down', size: 16 }},nativeOn:{"click":function($event){$event.stopPropagation();return _setup.toggleMenu.apply(null, arguments)}}})],1),(
      (_setup.isMenuOpened && !_setup.loading) ||
      (_setup.isMenuOpened && (_setup.filteredOptions.length || _setup.text))
    )?_c('portal',{attrs:{"to":"main-portal"}},[_c('transition',{attrs:{"name":"unroll","mode":"in-out"}},[_c(_setup.selectMenu,{key:_setup.menuTop,ref:"menu",attrs:{"text":_setup.text,"mode":_vm.mode,"top":_setup.menuTop,"left":_setup.menuLeft,"active":_setup.active,"width":_setup.menuWidth,"loading":_setup.loading,"bottom":_setup.menuBottom,"disabled":_vm.disabled,"multiple":_vm.multiple,"qty-page":_setup.currentPage,"alias_key":_setup.alias_key,"cache-query":_setup.cacheQuery,"total-count":_setup.totalCount,"can-be-empty":_vm.canBeEmpty,"no-select-all":_setup.noSelectAll,"is-filterable":_setup.isFilterable,"has-no-text-data":_setup.hasNoTextData,"filtered-options":_setup.filteredOptions,"service-options":!!_setup.initialServiceHandler,"cache-display-options":_setup.cacheDisplayOptions,"cache-endpoint-options":_setup.cacheEndpointOptions},on:{"select-all":_setup.selectAll,"show-more":_setup.showMoreHandler,"item-click":_setup.itemClickHandler,"filter-input":_setup.filterInputHandler}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }