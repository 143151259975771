import { TILESERVER_MAP_HOST } from '@/config/constants'
import { updateLSSystemConfig } from '@/modules/user/helpers/helpers'
import $store from 'STORE'

export const toggleBodyTheme = async newTheme => {
  const theme = getTheme()

  if (newTheme) {
    await updateLSSystemConfig('theme', newTheme)
  }

  document.body.classList.remove(getTheme(theme))
  document.body.classList.add(getTheme(newTheme || theme))
}

export const getTheme = value => {
  const theme = value || $store.getters.getTheme || 'system'

  if (theme === 'system') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  }

  return theme
}

export const getEmptyMapStyleBasedOnTheme = () => {
  const body = document.querySelector('body')
  const style = window.getComputedStyle(body)
  const bgSurface = style.getPropertyValue('--bg-surface')

  return {
    version: 8,
    name: 'empty',
    // eslint-disable-next-line vue/max-len
    glyphs: `${TILESERVER_MAP_HOST}/tileserver_map/fonts/{fontstack}/{range}.pbf`,
    sources: {},
    layers: [
      {
        id: 'background',
        type: 'background',
        paint: {
          'background-color': bgSurface
        }
      }
    ]
  }
}
